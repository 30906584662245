<template>
  <div class="bottom">
    <p v-if="pageType">版权所有：{{pageinfo[pageType].text0}}</p>
    <p v-else>版权所有：北京红本科技有限公司</p>
    <p v-if="pageType">经营性ICP证：{{pageinfo[pageType].text1}}</p>
    <p v-if="pageType"><a href='http://beian.miit.gov.cn'>{{pageinfo[pageType].text2}}</a></p>
    <p v-if="pageType" class="bottom-img">
      <img src="@/assets/images/procedure/police.png" alt="">
      <a :href='`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${pageinfo[pageType].text3}`' >{{pageinfo[pageType].text3}}</a>
    </p>
    <p v-if="pageType">本网站常年法律顾问：北京市京悦律师事务所 张琳律师</p>
    <p v-if="pageStage == 4">投资有风险，选择需谨慎</p>
    <p>地址：北京市海淀区上地信息路11号彩虹大厦北楼西段203室</p>
    <p>电话：4008-017-018</p>
  </div>
</template>

<script>
export default {
  props: {
    pageStage: {
      type:[String,Number],
      default:null
    }
  },
  data () {
    return {
      pageType:0,
      pageinfo: {
        1:{
          text0:"北京红本科技有限公司",
          text1:"京B2-20231796",
          text2:"京ICP备2022015314号-2",
          text3:"11010802039553",
        },
        2:{
          text0:"北京国音红杉树智能科技有限公司",
          text1:"京B2-20192303",
          text2:"京ICP备16025391号-11",
          text3:"11010802037209",
        },
        3:{
          text0:"北京国音红杉树智能科技有限公司",
          text1:"京B2-20192303",
          text2:"京ICP备16025391号-10",
          text3:"11010802037209",
        },
        4:{
          text0:"北京国音红杉树智能科技有限公司",
          text1:"京B2-20192303",
          text2:"京ICP备16025391号-7",
          text3:"11010802037209",
        },
      }
    }
  },
  mounted(){
    let url = window.location.host;
    let pageType = null;
    if(url.indexOf('hongbenkeji.com')>-1){
      pageType = 1;
    }else if(url.indexOf('hongshanshu99.com')>-1){
      pageType = 2;
    }else if(url.indexOf('hongshanshu998.com')>-1){
      pageType = 3;
    }else if(url.indexOf('xiaohongben.com.cn')>-1){
      pageType = 4;
    }
    this.pageType = pageType;
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
      .bottom{
      padding: 36px 0;
      // background-color: #f5f9fa;
        p{
          color: #999999;
          font-weight: 400;
          font-size: 11.5px;
          text-align: center;
          line-height: 20.09px;
        }
        .bottom-img {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 2px;
          }
        }
      }

</style>
