<template>
  <div class="page-header">
    <div class="top">
      <div class="left"><img src="../assets/img/index/title.png" alt=""></div>
      <div class="right" @click="openNav()">
        <div class="imgs" v-if="!notShowNav"><img src="../assets/img/index/nav.png" v-if="!openNavFlag" alt=""> <img class="close" src="../assets/img/index/close.png" v-else alt=""></div>
        </div>
      <div class="navList" v-if="openNavFlag">
        <div class="nav_item1" @click="skip('/indexPage')">
          <div class="text">首页</div>
        </div>
        <div class="nav_item2" @click="skip('/about')">
          <div class="text">关于我们</div>
        </div>
        <div class="nav_item3" @click="skip('/coreTechnologies')">
          <div class="text">核心技术</div>
        </div>
        <div class="nav_item4" @click="skip('/project')">
          <div class="text">项目合作</div>
        </div>
      </div>
    </div>
    <div class="swiper_content">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in photoList" :key="item.img">
            <template v-if="!notShowNav">
              <img :src=" require('../assets/img/'+item.img)" alt="" @click="skip(item.path)">
            </template>
            <template v-else>
              <img :src=" require('../assets/img/'+item.img)" alt="">
            </template>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="nav" v-if="!notShowNav">
      <div class="nav_item1" @click="skip('/indexPage')">
        <div class="img"><img src="../assets/img/index/index.png" alt=""></div>
        <div class="text">首页</div>
      </div>
      <div class="nav_item2" @click="skip('/about')">
        <div class="img"><img src="../assets/img/index/about.png" alt=""></div>
        <div class="text">关于我们</div>
      </div>
      <div class="nav_item3" @click="skip('/coreTechnologies')">
        <div class="img"><img src="../assets/img/index/technology.png" alt=""></div>
        <div class="text">核心技术</div>
      </div>
      <div class="nav_item4" @click="skip('/project')">
        <div class="img"><img src="../assets/img/index/cooperate.png" alt=""></div>
        <div class="text">项目合作</div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);
import "swiper/swiper-bundle.css";
export default {
  props: {
  },
  data() {
    return {
      headerSwiper: null,
      notShowNav: false,
      openNavFlag: false,
      photoList: [
        {
          img:"index/index1.jpg",
          path:"/indexPage",
        },
        {
          img:"index/index1-old.jpg",
          path:"/indexPage",
        },
        {
          img:"index/index2.jpg",
          path:"/about",
        },
        {
          img:"index/index3.jpg",
          path:"/coreTechnologies",
        },
        {
          img:"index/index4.jpg",
          path:"/project",
        },
      ],
    };
  },
  created(){
    console.log(this.$route.path);
    if(this.$route.path == '/indexPage'){
      if(this.$route.query.notShowNav){
        this.notShowNav = true;
      }
     
    }
  },  
  mounted() {
    let list = [
      "/indexPage",
      "/indexPage",
      "/about",
      "/coreTechnologies",
      "/project"
    ]
    let index = list.indexOf(this.$route.path);
    console.log('%c [ index ]-114', 'font-size:13px; background:pink; color:#bf2c9f;', index)
    this.$nextTick(() => {
   this.headerSwiper =   new Swiper ('.swiper', {
        direction: 'horizontal', // 垂直切换选项
        loop: true, // 循环模式选项
        initialSlide: index>=0?index:0,
        
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          click:true,
        },
        // autoplay: {
        // delay: 2000,
        // disableOnInteraction: true,
        // },
        observer: true,
        observeParents: true,
        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      })   
      // setInterval(()=>{
      //   if(this.swiper2[0]){
      //     this.swiper2[0].slideNext();
      //   }else{
      //     this.swiper2.slideNext();
      //   }
      //   // this.swiper2[1].slideNext();
      // },2000)
    });
  },
  methods: {
    skip(path){
      this.$router.push({ path: path });
    },
    openNav(){
      this.openNavFlag =!this.openNavFlag;
    
    }
  },
};
</script>

<style lang='scss' scoped>
.page-header {
  width: 100%;
  // height: 100%;
  background: #fff;
  position: relative;
  .top {
    position: relative;
    padding: 13.5px 20px 13.5px 23.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 195.5px;
      height: 29.5px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 24px;
      height: 14.5px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
      .close{
        width: 18px;
        height: 18px;
      }
    }
    .navList{
      position: absolute;
      right: 12px;
      top: 70px;
      z-index: 999999;
      border-radius: 5px;
      width: 70px;
      height: 180px;
      background-color: rgba(255, 255, 255,0.7);
      div{
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
        color: #333333;
        height: 40px;
        line-height: 40px;
      }
      .nav_item1,.nav_item2,.nav_item3{
        border-bottom: 1px solid #ccc;
      }
    } 
  }
  .nav {
    width: 100%;
    height: 110px;
    box-shadow: 2.5px -0.5px 5px 1.5px #a5a5a52e;
    background-color: #fff;
    margin-top: 8.5px;
    display: flex;
    padding: 30px 28.5px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    .nav_item1 {
      .img {
        width: 38.5px;
        height: 34.5px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }
    .nav_item2 {
      .img {
        width: 36.5px;
        height: 36.5px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }
    .nav_item3 {
      .img {
        width: 36.5px;
        height: 36.5px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }
    .nav_item4 {
      .img {
        width: 36.5px;
        height: 36.5px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }
  }
  .swiper {
    width: 375px;
    height: 293px;
    --swiper-pagination-color: #fff; /* 两种都可以 */
    .swiper-slide {
      width: 375px;
      height: 293px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
